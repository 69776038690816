<template>
    <div class="form">
        <div class="form_title">Website data</div>
        <a-spin :spinning="spinning">
            <a-form :model="formData" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-item label="Title">
                <a-input v-model:value="formData.title" placeholder="Title" />
                </a-form-item>
                <a-form-item label="Logo">
                    <img :src="formData.logo_url" class="image" v-if="formData.logo_url.length > 0" >
                    <a-upload :customRequest="(file) => customRequest('logo', file.file)">
                        <a-button>
                        <component :is="$antIcons['UploadOutlined']" />
                        Upload
                        </a-button>
                    </a-upload>
                </a-form-item>
                <a-form-item label="Home image">
                    <img :src="formData.home_image_url" class="image" v-if="formData.home_image_url.length > 0" >
                    <a-upload :customRequest="(file) => customRequest('home_image', file.file)">
                        <a-button>
                        <component :is="$antIcons['UploadOutlined']" />
                        Upload
                        </a-button>
                    </a-upload>
                </a-form-item>
                <a-form-item label="Heading">
                <a-input v-model:value="formData.heading" placeholder="Heading" />
                </a-form-item>
                <a-form-item label="Sub Heading">
                <a-input v-model:value="formData.sub_heading" placeholder="Sub Heading" />
                </a-form-item>
                <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                <a-button type="primary" @click="updateWebsiteHandler">Update</a-button>
                </a-form-item>
            </a-form>
        </a-spin>
    </div>
</template>
<script>
import { message } from 'ant-design-vue'
import { upload, getWebsiteInfo, updateWebsite } from '@/apis/system'
export default {
    name: 'Website setting',
    data(){
        return {
            formData: {
                title: '',
                heading: '',
                sub_heading: '',
                logo: '',
                home_image: '',
                logo_url: '',
                home_image_url: '',
            },
            labelCol: { span: 6 },
            wrapperCol: { span: 12 },
            fileList: [],
            spinning: false,
        }
    },
    methods: {
        /**
         * 上传
         */
        async customRequest(key, file){
            this.spinning = true
            const res = await upload(file)
            this.spinning = false
            if(res){
                this.formData[key] = res.name
                this.formData[key+'_url'] = res.file_name
            }
        },
        async getWebsiteInfoHandler(){
            this.spinning = true
            const res = await getWebsiteInfo()
            this.spinning = false
            if(res){
                this.formData = res.data
            }
        },
        async updateWebsiteHandler(){
            this.spinning = true
            const res = await updateWebsite(this.formData)
            this.spinning = false
            if(res){
                message.success('success')
                this.getWebsiteInfoHandler()
            }
        }
    },
    created(){
        this.getWebsiteInfoHandler()
    }
}
</script>
<style lang="stylus" scoped>
.image
    width 180px
    display block
    margin-bottom 10px
</style>